import { gql } from "@apollo/client";

export const IS_AUTH_QUERY = gql`
  {
    isAuthenticated {
      status
    }
    getUserByToken {
      accountingFirms
      companies
      isActive
      mfaActive
      userCategory {
        id
        name
        slug
      }
      isSuperuser
      isNewraiUser
      id
      firstName
      lastName
      email
      username
      groups {
        id
        name
        userType
        order
        slug
      }
      permissions
      timezone
      language
      subscribedNewsletter
      receiveNotificationsByEmail
    }
  }
`;

export const LIST_COUNTRIES_QUERY = gql`
  query listCountriesPaginated($search: String, $limit: Int, $offset: Int) {
    listCountriesPaginated(filter: { name_Istartswith: $search }) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: "name") {
        code
        name
      }
    }
  }
`;
