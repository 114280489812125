import { useLazyQuery } from "@apollo/client";
import { useOnErrorGraphql } from "@newrai/ant-design";
import { clientCentralApi } from "@newrai/api-clients";
import { routers } from "config/routes";
import useStore from "config/store";
import { GET_LOGO_ACCOUNTING_FIRM } from "queries/centralApi/accountingFirms";
import { GET_FIRST_COMPANY } from "queries/centralApi/general";
import { useState } from "react";
import newRaiLogo from "resources/images/logo-blue.png";
import { getImage } from "utils/downloadFile";

interface useLazyQueryGetLogoAccountingFirmResponse {
  setLogo: (value: ((prevState: string) => string) | string) => void;
  getLogo: (options?: any) => any;
  data: any;
  logo: string;
  refetch: (variables?: any) => any;
}

interface useLazyQueryListCompaniesResponse {
  data: any;
  refetch: (variables?: any) => any;
  listCompanies: (options?: any) => any;
}

export function useLazyQueryGetLogoAccountingFirm(): useLazyQueryGetLogoAccountingFirmResponse {
  const [logo, setLogo] = useState<string>("");
  const { onErrorGraphql } = useOnErrorGraphql();
  const [getLogo, { data, refetch }] = useLazyQuery(GET_LOGO_ACCOUNTING_FIRM, {
    client: clientCentralApi,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError: onErrorGraphql,
    onCompleted: async data => {
      const listAccountingFirms = data.listAccountingFirmsPaginated?.results.filter(
        item => item.logo,
      );
      listAccountingFirms.length > 0 &&
        setLogo(await getImage(listAccountingFirms[0].logo || newRaiLogo));
    },
  });

  return { getLogo, logo, data, refetch, setLogo };
}

export function useLazyQueryListCompanies(): useLazyQueryListCompaniesResponse {
  const setCompanySelected = useStore(state => state.setCompanySelected);
  const { onErrorGraphql } = useOnErrorGraphql();

  const [listCompanies, { data, refetch }] = useLazyQuery(GET_FIRST_COMPANY, {
    client: clientCentralApi,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError: onErrorGraphql,
    onCompleted: data => {
      const company = data.listCompaniesPaginated ? data.listCompaniesPaginated?.results : [];
      if (company.length > 0) {
        const companySelectedData = {
          accountingFirm: company[0].accountingFirm,
          accountingFirmId: company[0].accountingFirm?.id,
          company: company[0],
          companyId: company[0].id,
        };
        setCompanySelected(companySelectedData);
        routers.navigate(`/company/${company[0].id}`, { replace: true });
      }
    },
  });

  return { listCompanies, data, refetch };
}
