import { Spin } from "antd";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const antIcon = <AiOutlineLoading3Quarters style={{ fontSize: 70 }} />;

export default function LoadingPage() {
  return (
    <div className="loading-container">
      <Spin className="loading-position" indicator={antIcon} />
    </div>
  );
}
