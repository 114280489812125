import useStore from "config/store";
import useAccountingFirmPermissions from "hooks/Permissions/useAccountingFirmPermissions";
import useCompanyPermissions from "hooks/Permissions/useCompanyPermissions";
import useGeneralPermissions from "hooks/Permissions/useGeneralPermissions";
import { useTranslation } from "react-i18next";
import { FaBuilding, FaCalculator, FaHome, FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";

const CenteredItems = () => {
  const { t } = useTranslation();

  const userData = useStore(state => state.userData);

  const { canDisplayUser, canDisplayAllUsers } = useGeneralPermissions();
  const { canDisplayCompaniesList } = useCompanyPermissions();
  const { canDisplayAccountingFirms } = useAccountingFirmPermissions();

  return !["client", "client-without-accounting"].includes(userData?.userCategory?.slug) ? (
    <div className="toopbar__menu">
      <Link id="home-nav" to="/" className="topbar__button p-link">
        <FaHome className="topbar__button-icon-svg" />
        <span className="topbar__text">{t("Home")}</span>
      </Link>
      {!!canDisplayAccountingFirms && (
        <Link id="accounting-firms-nav" to="/accounting-firms" className="topbar__button p-link">
          <FaCalculator className="topbar__button-icon-svg" />

          <span className="topbar__text">{t("Accounting Firms")}</span>
        </Link>
      )}
      {!!canDisplayUser && (
        <Link id="users-nav" to="/users" className="topbar__button p-link">
          <FaUsers className="topbar__button-icon-svg" />
          <span className="topbar__text">
            {canDisplayAllUsers ? t("Users") : t("Collaborators")}
          </span>
        </Link>
      )}
      {!!canDisplayCompaniesList && (
        <Link id="companies-nav" to="/companies" className="topbar__button p-link">
          <FaBuilding className="topbar__button-icon-svg" />
          <span className="topbar__text">{t("Companies")}</span>
        </Link>
      )}
    </div>
  ) : (
    <></>
  );
};

export default CenteredItems;
