export const usersPermissions = {
  displayAllUsers: ["dashboard.can-display-all-users"],
  update: [
    "neoauth.can-update-users",
    "central-api.can-view-companies",
    "central-api.can-view-accounting-firms",
  ],
  create: [
    "neoauth.can-create-users",
    "central-api.can-view-companies",
    "central-api.can-view-accounting-firms",
  ],
  display: ["dashboard.can-display-users"],
};

export const updateProfilePermission = ["neoauth.can-update-profile"];

export const viewBanksPermission = ["banks-api.can-view-banks"];
