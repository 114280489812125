import Cookies from "js-cookie";
import axios from "axios";

export function downloadFile(url) {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function getImage(url) {
  const token = Cookies.get("access_token");

  const response = await axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  });

  return window.URL.createObjectURL(response.data);
}
