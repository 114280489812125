import {
  assignmentsPermissions,
  banksPermissions,
  booksPermissions,
  clientsPermissions,
  companyAccountingPermissions,
  companyPermissions,
  legalPermissions,
} from "./RequiredPermissions/company";
import useCheckPermissions from "./useCheckPermissions";

export default function useCompanyPermissions() {
  const canDisplayCompaniesList = useCheckPermissions(companyPermissions.displayList);
  const canViewCompaniesList = useCheckPermissions(companyPermissions.viewList);
  const canCreateCompany = useCheckPermissions(companyPermissions.create);
  const canUpdateCompany = useCheckPermissions(companyPermissions.update);
  const canDeleteCompany = useCheckPermissions(companyPermissions.delete);
  const canDisplayCompanyGeneral = useCheckPermissions(companyPermissions.displayGeneral);
  const canUpdateCompanySettings = useCheckPermissions(companyPermissions.updateSettings);
  const canDisplayCompanySettings = useCheckPermissions(companyPermissions.displaySettings);
  const canDisplayCompanyAddress = useCheckPermissions(companyPermissions.displayAdress);
  const canManageCompaniesBanks = useCheckPermissions(companyPermissions.manageBanks);
  const canAddRemoveAddon = useCheckPermissions(companyPermissions.addRemoveAddon);
  const canViewDossierProgress = useCheckPermissions(companyPermissions.viewDossierProgress);

  //   Legal
  const canDisplayCompanyLegal = useCheckPermissions(legalPermissions.display);
  const canUpdateCompanyFiscal = useCheckPermissions(legalPermissions.updateFiscal);
  const canDisplayCorporateDirectors = useCheckPermissions(
    legalPermissions.displayCorporateDirectors,
  );
  const canCreateCorporateDirectors = useCheckPermissions(
    legalPermissions.createCorporateDirectors,
  );
  const canUpdateCorporateDirectors = useCheckPermissions(
    legalPermissions.updateCorporateDirectors,
  );
  const canDeleteCorporateMembers = useCheckPermissions(legalPermissions.deleteCorporateMembers);
  const canDisplayCorporateStakeholders = useCheckPermissions(
    legalPermissions.displayCorporateStakeholders,
  );
  const canCreateCorporateStakeholders = useCheckPermissions(
    legalPermissions.createCorporateStakeholders,
  );
  const canUpdateCorporateStakeholders = useCheckPermissions(
    legalPermissions.updateCorporateStakeholders,
  );

  //   Accounting
  const canDisplayCounterParts = useCheckPermissions(
    companyAccountingPermissions.displayCounterparts,
  );
  const canCreateCounterParts = useCheckPermissions(
    companyAccountingPermissions.createCounterparts,
  );
  const canUpdateCounterParts = useCheckPermissions(
    companyAccountingPermissions.updateCounterparts,
  );

  const canDisplayVats = useCheckPermissions(companyAccountingPermissions.displayVats);
  const canCreateVats = useCheckPermissions(companyAccountingPermissions.createVats);
  const canUpdateVats = useCheckPermissions(companyAccountingPermissions.updateVats);
  const canDeleteVats = useCheckPermissions(companyAccountingPermissions.deleteVats);

  const canDisplayCycles = useCheckPermissions(companyAccountingPermissions.displayCycles);
  const canUpdateCycles = useCheckPermissions(companyAccountingPermissions.updateCycles);

  const canDisplayProviders = useCheckPermissions(companyAccountingPermissions.displayProviders);
  const canViewProviders = useCheckPermissions(companyAccountingPermissions.viewProviders);
  const canUpdateProviders = useCheckPermissions(companyAccountingPermissions.updateProviders);
  const canCreateProvider = useCheckPermissions(companyAccountingPermissions.createProvider);
  const canCreateProviderBankAccount = useCheckPermissions(
    companyAccountingPermissions.createProviderBankAccount,
  );
  const canUpdateProviderBankAccount = useCheckPermissions(
    companyAccountingPermissions.updateProviderBankAccount,
  );
  const canDeleteProviderBankAccount = useCheckPermissions(
    companyAccountingPermissions.deleteProviderBankAccount,
  );

  const canDisplaySubsidiaries = useCheckPermissions(
    companyAccountingPermissions.displaySubsidiaries,
  );
  const canCreateClient = useCheckPermissions(companyAccountingPermissions.createClients);

  const canImport = useCheckPermissions(companyAccountingPermissions.import);
  const canExport = useCheckPermissions(companyAccountingPermissions.export);
  const canDisplayImportExport = useCheckPermissions(
    companyAccountingPermissions.displayImportExport,
  );

  //   Assignments
  const canCreateAssignments = useCheckPermissions(assignmentsPermissions.create);
  const canUpdateAssignments = useCheckPermissions(assignmentsPermissions.update);
  const canDeleteAssignments = useCheckPermissions(assignmentsPermissions.delete);
  const canDisplayAssignments = useCheckPermissions(assignmentsPermissions.display);

  //   Books
  const canDisplayBooks = useCheckPermissions(booksPermissions.display);
  const canCreateBooks = useCheckPermissions(booksPermissions.create);
  const canUpdateBooks = useCheckPermissions(booksPermissions.update);
  const canUpdateBookTypesAllBooks = useCheckPermissions(booksPermissions.updateBookTypesAllBooks);

  //   Clients
  const canDisplayClients = useCheckPermissions(clientsPermissions.display);
  const canViewClients = useCheckPermissions(clientsPermissions.view);
  const canUpdateClients = useCheckPermissions(clientsPermissions.update);

  //   Banks
  const canCreateBank = useCheckPermissions(banksPermissions.createBank);
  const canUpdateBank = useCheckPermissions(banksPermissions.updateBank);
  const canDeleteBank = useCheckPermissions(banksPermissions.deleteBank);
  const canCreateBankAccount = useCheckPermissions(banksPermissions.createBankAccount);
  const canUpdateBankAccount = useCheckPermissions(banksPermissions.updateBankAccount);
  const canDeleteBankAccount = useCheckPermissions(banksPermissions.deleteBankAccount);

  return {
    canCreateAssignments,
    canUpdateAssignments,
    canDeleteAssignments,
    canDisplayAssignments,

    canDisplayBooks,
    canCreateBooks,
    canUpdateBooks,
    canUpdateBookTypesAllBooks,

    canDisplayClients,
    canViewClients,
    canUpdateClients,
    canDisplayProviders,
    canViewProviders,
    canUpdateProviders,
    canCreateProvider,
    canCreateProviderBankAccount,
    canUpdateProviderBankAccount,
    canDeleteProviderBankAccount,

    canDisplayCompaniesList,
    canViewCompaniesList,
    canCreateCompany,
    canUpdateCompany,
    canDeleteCompany,

    canDisplayCompanyGeneral,
    canUpdateCompanySettings,
    canDisplayCompanySettings,
    canDisplayCompanyAddress,
    canManageCompaniesBanks,
    canAddRemoveAddon,
    canViewDossierProgress,

    canDisplayCompanyLegal,
    canUpdateCompanyFiscal,
    canDisplayCorporateDirectors,
    canCreateCorporateDirectors,
    canUpdateCorporateDirectors,
    canDeleteCorporateMembers,
    canDisplayCorporateStakeholders,
    canCreateCorporateStakeholders,
    canUpdateCorporateStakeholders,

    canDisplayImportExport,
    canImport,
    canExport,
    canDisplayCounterParts,
    canCreateCounterParts,
    canUpdateCounterParts,
    canDisplayVats,
    canCreateVats,
    canUpdateVats,
    canDeleteVats,
    canDisplayCycles,
    canUpdateCycles,
    canDisplaySubsidiaries,
    canCreateClient,

    canCreateBank,
    canUpdateBank,
    canDeleteBank,
    canCreateBankAccount,
    canUpdateBankAccount,
    canDeleteBankAccount,
  };
}
