import useStore from "config/store";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { routers } from "./index";
import { usePermissionsRedirects } from "./usePermissionsRedirects";

interface PermissionRedirectProps {
  children: React.ReactNode;
  requiredPermission?: string;
}

export default function PermissionRedirect({
  children,
  requiredPermission,
}: PermissionRedirectProps) {
  const userData = useStore(state => state.userData);
  const location = useLocation();
  const { pathname } = location;

  const { companyId, accountingFirmId } = useParams();
  const id = companyId || accountingFirmId;

  const { permissionsRedirects } = usePermissionsRedirects(id);

  const checkUserPermissions = user => {
    if (!requiredPermission || !(requiredPermission in permissionsRedirects)) {
      const response = { hasPermission: true, redirectUrl: "" };

      if (accountingFirmId && pathname.includes("/accounting-firms/")) {
        response.hasPermission =
          user.accountingFirms.includes(accountingFirmId) || user.isSuperuser;
        response.redirectUrl = "/accounting-firms";
      } else if (companyId && pathname.includes("/companies/")) {
        response.hasPermission = user.companies.includes(companyId) || user.isSuperuser;
        response.redirectUrl = "/companies";
      }

      return response;
    }

    const { hasPermission, redirect } = permissionsRedirects[requiredPermission];
    const userHasPermission = hasPermission;

    return { hasPermission: userHasPermission, redirectUrl: userHasPermission ? "" : redirect };
  };

  useEffect(() => {
    if (userData) {
      const { hasPermission, redirectUrl } = checkUserPermissions(userData);

      if (!hasPermission) {
        routers.navigate(redirectUrl, { replace: true });
      }
    }
  }, [requiredPermission]);

  return <>{children}</>;
}
