export const accountingPermissions = {
  displayList: ["dashboard.can-display-accounting-firms"],
  create: ["central-api.can-create-accounting-firms"],
  update: ["central-api.can-update-accounting-firms"],
  view: ["central-api.can-view-accounting-firms"],
  displaySettings: ["dashboard.can-display-accounting-firm-settings"],
  updateSettings: ["central-api.can-update-accounting-firm-settings"],
  viewSettings: ["central-api.can-view-accounting-firm-settings"],
  displayPlatform: ["dashboard.can-display-platforms"],
  createPlatform: ["central-api.can-create-platform"],
  updatePlatform: ["central-api.can-update-platform"],
  deletePlatform: ["central-api.can-delete-platform"],
  displayServices: ["dashboard.can-display-services"],
  createSubscriptions: ["crm-api.can-create-subscriptions"],
  updateSubscriptions: ["crm-api.can-update-subscriptions"],
  displayAddresses: ["dashboard.can-display-accounting-firm-addresses"],
  displayContacts: ["dashboard.can-display-accounting-firm-contacts"],
  createBankAccount: ["banks-api.can-create-accounting-firms-banks"],
  updateBankAccount: ["banks-api.can-update-accounting-firms-banks"],
  deleteBankAccount: ["banks-api.can-delete-accounting-firms-banks"],
};
