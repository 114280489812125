export const createAccountingFirmOption = item => {
  return {
    label: item.name,
    value: item,
  };
};

export const createCompanyOption = item => {
  return {
    label: `${item.internalReference} - ${item.name}`,
    value: item,
  };
};
