import useAccountingFirmPermissions from "hooks/Permissions/useAccountingFirmPermissions";
import useCompanyPermissions from "hooks/Permissions/useCompanyPermissions";
import useGeneralPermissions from "hooks/Permissions/useGeneralPermissions";

export function usePermissionsRedirects(id: ID) {
  const { canUpdateProfile, canCreateUser, canDisplayUser, canUpdateUser, canViewBanks } =
    useGeneralPermissions();

  const {
    canCreateAccountingFirm,
    canUpdateAccountingFirm,
    canViewAccountingFirmSettings,
    canDisplayAccountingFirms,
    canDisplayAccountingFirmAddress,
    canDisplayAccountingFirmContacts,
    canDisplayServices,
    canDisplayPlatforms,
  } = useAccountingFirmPermissions();

  const {
    canDisplayBooks,
    canDisplayClients,
    canDisplayProviders,
    canUpdateCompany,
    canCreateCompany,
    canDisplayCompanySettings,
    canDisplayAssignments,
    canDisplayCompanyAddress,
    canDisplayCompanyLegal,
    canDisplayCorporateDirectors,
    canDisplayCorporateStakeholders,
    canDisplayCompanyGeneral,
    canDisplayImportExport,
    canImport,
    canExport,
    canDisplayCounterParts,
    canViewDossierProgress,
    canDisplayVats,
    canDisplayCycles,
    canDisplaySubsidiaries,
    canDisplayCompaniesList,
  } = useCompanyPermissions();

  const permissionsRedirects = {
    displayAccountingFirms: {
      hasPermission: canDisplayAccountingFirms,
      redirectUrl: "/",
    },
    createAccountingFirms: {
      hasPermission: canCreateAccountingFirm,
      redirectUrl: "/accounting-firms",
    },
    updateAccountingFirms: {
      hasPermission: canUpdateAccountingFirm,
      redirectUrl: `/accounting-firms/${id}`,
    },
    canDisplayAccountingBanks: {
      hasPermission: canViewBanks,
      redirect: "/accounting-firms",
    },
    canDisplayAccountingAdress: {
      hasPermission: canDisplayAccountingFirmAddress,
      redirect: "/accounting-firms",
    },
    canDisplayAccountingContacts: {
      hasPermission: canDisplayAccountingFirmContacts,
      redirect: "/accounting-firms",
    },
    canDisplayAccountingPlatforms: {
      hasPermission: canDisplayPlatforms,
      redirect: "/accounting-firms",
    },
    canDisplayAccountingServices: {
      hasPermission: canDisplayServices,
      redirect: "/accounting-firms",
    },
    canDisplayAccountingCompanies: {
      hasPermission: canDisplayCompaniesList,
      redirect: "/accounting-firms",
    },
    canDisplayAccountingSettings: {
      hasPermission: canViewAccountingFirmSettings,
      redirect: "/accounting-firms",
    },
    displayUsers: {
      hasPermission: canDisplayUser,
      redirectUrl: "/",
    },
    createUsers: {
      hasPermission: canCreateUser,
      redirectUrl: "/users",
    },
    updateUsers: {
      hasPermission: canUpdateUser,
      redirectUrl: "/users",
    },
    displayCompanies: {
      hasPermission: canDisplayCompaniesList,
      redirectUrl: "/",
    },
    createCompanies: {
      hasPermission: canCreateCompany,
      redirectUrl: "/companies",
    },
    updateCompanies: {
      hasPermission: canUpdateCompany,
      redirectUrl: "/companies",
    },
    updateProfile: {
      hasPermission: canUpdateProfile,
      redirectUrl: "/",
    },
    canDisplayCompanyGeneral: {
      hasPermission: canDisplayCompanyGeneral,
      redirect: "/companies",
    },
    canDisplayCompaniesBanks: {
      hasPermission: canViewBanks,
      redirect: "/companies",
    },
    canDisplayAssignments: {
      hasPermission: canDisplayAssignments,
      redirect: "/companies",
    },
    canDisplayCompanyLegal: {
      hasPermission: canDisplayCompanyLegal,
      redirect: "/companies",
    },
    canDisplayCorporateDirectors: {
      hasPermission: canDisplayCorporateDirectors,
      redirect: "/companies",
    },
    canDisplayCorporateStakeholders: {
      hasPermission: canDisplayCorporateStakeholders,
      redirect: "/companies",
    },
    canImport: {
      hasPermission: canImport,
      redirect: "/companies",
    },
    canExport: {
      hasPermission: canExport,
      redirect: "/companies",
    },
    canDisplayBooks: {
      hasPermission: canDisplayBooks,
      redirect: "/companies",
    },
    canDisplayVats: {
      hasPermission: canDisplayVats,
      redirect: "/companies",
    },
    canDisplayCounterParts: {
      hasPermission: canDisplayCounterParts,
      redirect: "/companies",
    },
    canDisplayCompanySettings: {
      hasPermission: canDisplayCompanySettings,
      redirect: "/companies",
    },
    canDisplayCompanyAddress: {
      hasPermission: canDisplayCompanyAddress,
      redirect: "/companies",
    },
    canDisplayClients: {
      hasPermission: canDisplayClients,
      redirect: "/companies",
    },
    canDisplayProviders: {
      hasPermission: canDisplayProviders,
      redirect: "/companies",
    },
    canDisplayCycles: {
      hasPermission: canDisplayCycles,
      redirect: "/companies",
    },
    canDisplaySubsidiaries: {
      hasPermission: canDisplaySubsidiaries,
      redirect: "/companies",
    },
    canImportExport: {
      hasPermission: canDisplayImportExport,
      redirect: "/companies",
    },
    canViewDossierProgress: {
      hasPermission: canViewDossierProgress,
      redirect: "/companies",
    },
  };

  return { permissionsRedirects };
}
