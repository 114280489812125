export const companyPermissions = {
  viewList: ["central-api.can-view-companies"],
  displayList: ["dashboard.can-display-companies"],
  create: [
    "central-api.can-create-companies",
    "central-api.can-view-accounting-firms",
    "central-api.can-view-platform",
  ],
  update: ["central-api.can-update-companies", "central-api.can-view-platform"],
  delete: ["central-api.can-delete-company"],
  displayGeneral: ["dashboard.can-display-companies-general"],
  updateSettings: ["central-api.can-update-company-settings"],
  displaySettings: ["dashboard.can-display-company-settings"],
  displayAdress: ["dashboard.can-display-company-address"],
  manageBanks: ["neolib-api.can-manage-banks"],
  addRemoveAddon: ["neoauth.can-remove-and-add-addon"],
  viewDossierProgress: ["neoauth.can-view-dossier-progress"],
};

export const legalPermissions = {
  display: ["dashboard.can-display-companies-legal"],
  updateFiscal: ["central-api.can-update-fiscals"],
  displayCorporateDirectors: [
    "central-api.can-view-corporate-members",
    "dashboard.can-display-corporate-members-is-director",
  ],
  createCorporateDirectors: [
    "central-api.can-create-corporate-members",
    "central-api.can-create-corporate-members-is-director",
  ],
  updateCorporateDirectors: [
    "central-api.can-update-corporate-members",
    "central-api.can-update-corporate-members-is-director",
  ],
  deleteCorporateMembers: ["central-api.can-delete-corporate-members"],
  displayCorporateStakeholders: [
    "central-api.can-view-corporate-members",
    "dashboard.can-display-corporate-members-is-stakeholder",
  ],
  createCorporateStakeholders: [
    "central-api.can-create-corporate-members",
    "central-api.can-create-corporate-members-is-stakeholder",
  ],
  updateCorporateStakeholders: [
    "central-api.can-update-corporate-members",
    "central-api.can-update-corporate-members-is-stakeholder",
  ],
};

export const companyAccountingPermissions = {
  displayCounterparts: ["dashboard.can-display-counter-parts"],
  createCounterparts: ["central-api.can-create-counter-parts", "central-api.can-view-vats"],
  updateCounterparts: ["central-api.can-view-vats", "central-api.can-update-counter-parts"],
  displayVats: ["dashboard.can-display-vats"],
  createVats: ["central-api.can-create-vats"],
  updateVats: ["central-api.can-update-vats", "central-api.can-view-counter-parts"],
  deleteVats: ["dashboard.can-delete-vats"],
  displayCycles: ["dashboard.can-display-cycles"],
  updateCycles: ["central-api.can-update-cycles"],
  displayProviders: ["dashboard.can-display-providers"],
  viewProviders: ["central-api.can-view-providers"],
  createProvider: ["central-api.can-create-providers"],
  updateProviders: [
    "central-api.can-update-providers",
    "central-api.can-view-counter-parts",
    "central-api.can-view-vats",
  ],
  createProviderBankAccount: ["banks-api.can-create-providers-banks"],
  updateProviderBankAccount: ["banks-api.can-update-providers-banks"],
  deleteProviderBankAccount: ["banks-api.can-delete-providers-banks"],
  displaySubsidiaries: ["dashboard.can-display-subsidiaries"],
  createClients: ["central-api.can-create-clients"],
  import: ["central-api.can-import"],
  export: ["central-api.can-export"],
  displayImportExport: ["central-api.can-import", "central-api.can-export"],
};

export const assignmentsPermissions = {
  create: ["central-api.can-create-assignments", "neoauth.can-view-users"],
  update: ["central-api.can-update-assignments", "neoauth.can-view-users"],
  delete: ["central-api.can-delete-assignment"],
  display: ["dashboard.can-display-assignments"],
};

export const booksPermissions = {
  display: ["dashboard.can-display-books"],
  create: ["central-api.can-create-books"],
  update: ["central-api.can-update-books"],
  updateBookTypesAllBooks: ["central-api.can-update-book-types-all-books"],
};

export const clientsPermissions = {
  display: ["dashboard.can-display-clients"],
  view: ["central-api.can-view-clients"],
  update: [
    "central-api.can-update-clients",
    "central-api.can-view-counter-parts",
    "central-api.can-view-vats",
  ],
};

export const banksPermissions = {
  createBank: ["banks-api.can-create-banks"],
  updateBank: ["banks-api.can-update-banks"],
  deleteBank: ["banks-api.can-delete-banks"],
  createBankAccount: ["banks-api.can-create-companies-banks"],
  updateBankAccount: ["banks-api.can-update-companies-banks"],
  deleteBankAccount: ["banks-api.can-delete-companies-banks"],
};
