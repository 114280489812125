import { useLazyQuery } from "@apollo/client";
import { getHashParams, useOnErrorGraphql } from "@newrai/ant-design";
import * as Sentry from "@sentry/react";
// import { Modal } from "antd";
import i18n from "config/i18n/i18n";
import { routers } from "config/routes";
import API from "config/settings";
import useStore from "config/store";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { IS_AUTH_QUERY } from "queries/neoAuth/general";
import { useEffect, useRef } from "react";
import { hotjar } from "react-hotjar";
// import { useTranslation } from "react-i18next";
// import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import minArrayObjectElement from "utils/minArrayObjectElement";
import AppsMenu from "views/Base/components/AppsMenu";
import Header from "views/Base/components/Header";

// const { confirm } = Modal;

export default function PrivateRoute() {
  // const { t } = useTranslation();
  const userData = useStore(state => state.userData);

  const location = useLocation();
  const relativeUrlPath = useRef("");
  const { onErrorGraphql } = useOnErrorGraphql();
  const relativePathName = `${location.pathname}${location.search}`;

  const setUserData = useStore(state => state.setUserData);
  const userIsClient = ["client"].includes(userData?.userCategory && userData.userCategory.slug);
  const companySelect = useStore(state => state.companySelected);
  const companyId = companySelect?.companyId;

  const [getUserData] = useLazyQuery(IS_AUTH_QUERY, {
    onError: onErrorGraphql,
    notifyOnNetworkStatusChange: true,
  });

  const handleLoginUseEffect = async () => {
    relativeUrlPath.current = relativePathName;
    if (location.hash) {
      const expireSeconds = getHashParams(location, "expires_in");
      const expireDate = dayjs().add(Number(expireSeconds) * 1000, "ms");
      const accessTokenValue = getHashParams(location, "access_token");

      Cookies.set("access_token", accessTokenValue, {
        path: "/",
        expires: expireDate.toDate(),
      });
      Cookies.set("expireDate", expireDate.toISOString(), {
        path: "/",
        expires: expireDate.toDate(),
      });
    }

    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const relativeUrlPathParam = urlParams.get("relative_url_path");
      if (relativeUrlPathParam) {
        relativeUrlPath.current = relativeUrlPathParam;
      }
    }

    const cookieAccessToken = Cookies.get("access_token");
    if (cookieAccessToken && !userData) {
      const response = await getUserData();
      if (response.data?.getUserByToken) {
        const responseData = {
          ...response.data.getUserByToken,
          groupDefault: minArrayObjectElement(response.data.getUserByToken.groups, "order"),
        };
        Sentry.setUser({ email: responseData.email, id: responseData.id });
        setUserData(responseData);
        i18n.changeLanguage(response.data.getUserByToken.language.toLowerCase());
        relativeUrlPath.current && routers.navigate(relativeUrlPath.current, { replace: true });
        // if (!responseData?.mfaActive && responseData.isNewraiUser) {
        //   confirm({
        //     title: t("Two-factor authentication"),
        //     icon: (
        //       <span className="anticon">
        //         <AiOutlineExclamationCircle />
        //       </span>
        //     ),
        //     content: t(
        //       "Increase your account's security by enabling two-factor authentication (2FA).",
        //     ),
        //     onOk() {
        //       routers.navigate("/profile/settings");
        //     },
        //   });
        // }
        if (response.data.getUserByToken.isNewraiUser && process.env.NODE_ENV === "production") {
          hotjar.identify(response.data.getUserByToken.id, {
            first_name: response.data.getUserByToken.firstName,
            last_name: response.data.getUserByToken.lastName,
            email: response.data.getUserByToken.email,
          });
          hotjar.event("newrai_user");
        }
      } else {
        window.location.href = API.fullLoginUrl;
      }
    } else {
      window.location.href = API.fullLoginUrl;
      return;
    }
  };

  useEffect(() => {
    handleLoginUseEffect();
  }, []);

  const currentDomain =
    process.env.REACT_APP_ENV === "local"
      ? "https://dashboard.development.neonumy.dev/"
      : window.location.hostname;
  const urlIsNewRai = currentDomain.includes("newrai.fr") || currentDomain.includes("neonumy.dev");
  const notShowNotifications =
    (userData?.isSuperuser || userData?.permissions.includes("neofeed.can-access")) && urlIsNewRai;

  return (
    <>
      {userData ? (
        <>
          <Header isHome={window.location.pathname === "/" && notShowNotifications} />
          <Outlet />
          {((userIsClient && companyId) || userData?.userCategory?.slug !== "client") && (
            <AppsMenu />
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
}
