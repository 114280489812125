import { clientCentralApi } from "@newrai/api-clients";
import { routers } from "config/routes";
import useStore from "config/store";
import useCompanyPermissions from "hooks/Permissions/useCompanyPermissions";
import { GET_LIST_COMPANIES } from "queries/centralApi/general";
import { useTranslation } from "react-i18next";
import { createAccountingFirmOption, createCompanyOption } from "utils/selectOptions";

const SEARCH_LIMIT = 50;

export function useGetAndSaveCompanies() {
  const { t } = useTranslation();
  const { canViewCompaniesList } = useCompanyPermissions();
  const setIsCompanySelectOpen = useStore(state => state.setIsCompanySelectOpen);
  const isCompanySelectOpen = useStore(state => state.isCompanySelectOpen);
  const setCompanySelected = useStore(state => state.setCompanySelected);
  const companySelect = useStore(state => state.companySelected);
  const userData = useStore(state => state.userData);
  const setAccountingFirmSelected = useStore(state => state.setAccountingFirmSelected);

  /**
   * Function to get options list of companies from
   * listCompaniesPaginated query
   */
  const loadCompaniesOptions = async (search = "", prevOptions) => {
    let hasMore = false;
    const variables = {
      limit: SEARCH_LIMIT,
      offset: prevOptions.length,
      filter: {
        nameOrInternalReference: search,
      },
    };

    const response = await getMoreData({
      query: GET_LIST_COMPANIES,
      dataName: "listCompaniesPaginated",
      variables,
    });
    const resultsList = response?.results;
    const options = resultsList.map(companyItem => createCompanyOption(companyItem));

    hasMore = resultsList.length < response?.totalCount;
    return { options, hasMore };
  };

  /**
   * Function to get data list to async select
   */
  // eslint-disable-next-line
  const getMoreData = ({ query, dataName, variables }) => {
    return clientCentralApi
      .query({
        query,
        variables,
      })
      .then(({ data }) => {
        return data[dataName];
      });
  };

  const onSaveCompany = item => {
    setCompanySelected({
      accountingFirm: item.accountingFirm,
      accountingFirmId: item?.accountingFirm?.id,
      company: item,
      companyId: item.id,
    });
    setAccountingFirmSelected({
      id: item?.accountingFirm?.id,
      name: item?.accountingFirm?.name,
    });

    routers.navigate(`/company/${item.id}`);

    setIsCompanySelectOpen(false);
  };

  const hasMoreCompanies =
    userData?.companies.length > 1 &&
    ["client"].includes(userData?.userCategory?.slug) &&
    canViewCompaniesList;

  const selectedCompanyOptions = () => {
    if (["client"].includes(userData?.userCategory?.slug) && canViewCompaniesList) {
      return {
        accountingFirmValue: companySelect?.accountingFirm,
        companyValue: companySelect?.company,
        isModalOpen: isCompanySelectOpen,
        loadAccountingFirmsOptions: undefined,
        loadCompaniesOptions: loadCompaniesOptions,
        onSave: onSaveCompany,
        selectedAccountingFirmsOptionFormat: createAccountingFirmOption,
        selectedCompanyOptionFormat: createCompanyOption,
        companyInputOnly: true,
        maskClosable: !companySelect,
        blockOnCancel: companySelect,
        translations: {
          logout: t("Logout"),
          title: {
            dialog: t("Select company"),
            accompanyFirmDropdown: t("Accounting Firm"),
            companyDropdown: t("Company"),
          },
          button: {
            close: t("Close"),
            save: t("Submit"),
          },
          loadingMessage: `${t("Loading")}...`,
          noOptionsMessage: t("No options"),
          placeholder: `${t("Select")}...`,
        },
      };
    }
  };

  return { loadCompaniesOptions, hasMoreCompanies, selectedCompanyOptions };
}
