import {
  updateProfilePermission,
  usersPermissions,
  viewBanksPermission,
} from "./RequiredPermissions/general";
import useCheckPermissions from "./useCheckPermissions";

export default function useGeneralPermissions() {
  const canUpdateProfile = useCheckPermissions(updateProfilePermission);
  const canDisplayAllUsers = useCheckPermissions(usersPermissions.displayAllUsers);
  const canCreateUser = useCheckPermissions(usersPermissions.create);
  const canUpdateUser = useCheckPermissions(usersPermissions.update);
  const canDisplayUser = useCheckPermissions(usersPermissions.display);
  const canViewBanks = useCheckPermissions(viewBanksPermission);

  return {
    canUpdateProfile,
    canDisplayAllUsers,
    canDisplayUser,
    canCreateUser,
    canUpdateUser,
    canViewBanks,
  };
}
