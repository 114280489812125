import LoadingPage from "views/Base/LoadingPage";
import { Suspense } from "react";
import PermissionRedirect from "./PermissionRedirect";

interface ElementProps {
  children: React.ReactNode;
  requiredPermission?: string;
}

export default function Element(props: ElementProps) {
  const { requiredPermission } = props;
  return (
    <Suspense fallback={<LoadingPage />}>
      <PermissionRedirect requiredPermission={requiredPermission}>
        {props.children}
      </PermissionRedirect>
    </Suspense>
  );
}
