import { gql } from "@apollo/client";

export const LIST_COUNTERPARTS_QUERY = gql`
  query ($company: ID) {
    listCounterparts(filter: { company: $company }) {
      name
      remotePk
      id
      code
    }
  }
`;

export const LIST_VATS_QUERY_PAGINATED = gql`
  query listVatsPaginated($filter: VatFilterInput, $limit: Int, $offset: Int, $orderBy: [String]) {
    listVatsPaginated(filter: $filter) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        id
        rate
        code
      }
    }
  }
`;

export const GET_LIST_COMPANIES = gql`
  query listCompaniesPaginated($limit: Int, $offset: Int, $filter: CompanyFilterInput) {
    listCompaniesPaginated(filter: $filter) {
      totalCount
      results(limit: $limit, offset: $offset) {
        id
        name
        internalReference
        accountingFirm {
          id
          name
          logo
        }
        logo
      }
    }
  }
`;

export const GET_FIRST_COMPANY = gql`
  {
    listCompaniesPaginated {
      totalCount
      results(limit: 1, offset: 0) {
        id
        name
        internalReference
        accountingFirm {
          id
          name
          logo
        }
        logo
      }
    }
  }
`;
