import { useRef } from "react";
import { RouterProvider } from "react-router-dom";
import { I18nextProvider, useTranslation } from "react-i18next";
import "./resources/styles/styles.less";
import i18n from "config/i18n/i18n";
import { ConfigProvider } from "antd";
import fr_FR from "antd/lib/locale/fr_FR";
import en_GB from "antd/lib/locale/en_GB";
import "react-phone-input-2/lib/style.css";
import { ErrorBoundary } from "@newrai/ant-design";
import Logo from "resources/images/logo-blue.png";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjsBusinessDays from "dayjs-business-days2";
import Cookies from "js-cookie";
import { routers } from "config/routes";

const CONFIG_TRANSLATIONS = {
  fr: fr_FR,
  en: en_GB,
};

dayjs.extend(dayjsBusinessDays);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

export default function App() {
  const { t } = useTranslation();
  const cookie = useRef();

  const checkCookie = () => {
    if (!cookie.current) {
      cookie.current = Cookies.get("access_token");
    } else if (cookie.current !== Cookies.get("access_token")) {
      window.location.reload();
    }
  };

  window.setInterval(checkCookie, 1000);

  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider locale={CONFIG_TRANSLATIONS[i18n.language]}>
        <ErrorBoundary
          logo={Logo}
          translations={{
            message: t("An error occurred, please refresh the page or click the button below"),
            refreshButtonText: t("Refresh page"),
            title: t("Error"),
          }}
        >
          <RouterProvider router={routers} />
        </ErrorBoundary>
      </ConfigProvider>
    </I18nextProvider>
  );
}
