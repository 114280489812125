import PrivateRoute from "config/routes/PrivateRoute";
import { lazy, Suspense } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom";
import LoadingPage from "views/Base/LoadingPage";
import Element from "./Element";

const Page404 = lazy(() => import("views/Base/Page404"));
const AccountingFirmsList = lazy(() => import("views/AccountingFirm/AccountingFirmsList"));
const EntityTableCard = lazy(() => import("components/EntityTableCard"));
const AccountingFirmsAdd = lazy(() => import("views/AccountingFirm/AccountingFirmsAdd"));
const AccountingFirmsDetail = lazy(() => import("views/AccountingFirm"));
const UsersEdit = lazy(() => import("views/Collaborators/Edit"));
const UsersAdd = lazy(() => import("views/Collaborators/Add"));
const UsersList = lazy(() => import("views/Collaborators"));
const CompaniesAdd = lazy(() => import("views/Company/CompaniesAdd"));
const CompaniesDetail = lazy(() => import("views/Company/index"));
const CompaniesList = lazy(() => import("views/Company/CompaniesList"));
const Highligth = lazy(() => import("views/Home/NewsFeed/Highligths"));
const ListArticles = lazy(() => import("views/Home/NewsFeed/ListArticles"));
const Article = lazy(() => import("views/Home/NewsFeed/Article"));
const Home = lazy(() => import("views/Home/"));
const Profile = lazy(() => import("views/Profile"));

const routers = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<PrivateRoute />}>
      <Route
        path="/"
        element={
          <Element>
            <Home />
          </Element>
        }
      >
        <Route
          index
          element={
            <Element>
              <Highligth />
            </Element>
          }
        />
        <Route path="company/:companyId" element={<Highligth />} />

        <Route
          path="articles/:categoryId/:articleId"
          element={
            <Element>
              <Article />
            </Element>
          }
        />
        <Route
          path="articles/:categoryId"
          element={
            <Element>
              <ListArticles />
            </Element>
          }
        />
      </Route>
      <Route
        path="users"
        element={
          <Element requiredPermission="displayUsers">
            <EntityTableCard />
          </Element>
        }
      >
        <Route
          index
          element={
            <Element requiredPermission="displayUsers">
              <UsersList />
            </Element>
          }
        />
        <Route
          path=":userId/edit"
          element={
            <Element requiredPermission="updateUsers">
              <UsersEdit />
            </Element>
          }
        />
        <Route
          path="add"
          element={
            <Element requiredPermission="createUsers">
              <UsersAdd />
            </Element>
          }
        />
      </Route>
      <Route path="account">
        <Route index element={<Navigate to={`/account/profile`} replace />} />
        <Route
          path=":menu"
          element={
            <Element requiredPermission="updateProfile">
              <Profile />
            </Element>
          }
        />
      </Route>
      <Route
        path="accounting-firms"
        element={
          <Element requiredPermission="displayAccountingFirms">
            <EntityTableCard />
          </Element>
        }
      >
        <Route
          index
          element={
            <Element requiredPermission="displayAccountingFirms">
              <AccountingFirmsList />
            </Element>
          }
        />
        <Route
          path="add"
          element={
            <Element requiredPermission="createAccountingFirms">
              <AccountingFirmsAdd />
            </Element>
          }
        />
      </Route>
      <Route path="accounting-firms">
        <Route
          path=":accountingFirmId/:menu"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AccountingFirmsDetail />
            </Suspense>
          }
        >
          <Route
            path=":subMenu"
            element={
              <Suspense fallback={<LoadingPage />}>
                <AccountingFirmsDetail />
              </Suspense>
            }
          />
        </Route>
      </Route>

      <Route
        path="companies"
        element={
          <Element requiredPermission="displayCompanies">
            <EntityTableCard />
          </Element>
        }
      >
        <Route
          index
          element={
            <Element requiredPermission="displayCompanies">
              <CompaniesList />
            </Element>
          }
        />
        <Route
          path="add"
          element={
            <Element requiredPermission="createCompanies">
              <CompaniesAdd />
            </Element>
          }
        />
      </Route>
      <Route path="companies">
        <Route
          path=":companyId"
          element={
            <Element requiredPermission="displayCompanies">
              <CompaniesDetail />
            </Element>
          }
        />
        <Route
          path=":companyId/:menu"
          element={
            <Suspense fallback={<LoadingPage />}>
              <CompaniesDetail />
            </Suspense>
          }
        >
          <Route
            path=":subMenu"
            element={
              <Suspense fallback={<LoadingPage />}>
                <CompaniesDetail />
              </Suspense>
            }
          />
        </Route>
      </Route>
      <Route
        path="store-token"
        element={
          <Element>
            <Home />
          </Element>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Page404 />
          </Suspense>
        }
      />
    </Route>,
  ),
);

export { routers };
