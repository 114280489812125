import { SidebarApps } from "@newrai/ant-design";
import useStore from "config/store";

export default function AppsMenu() {
  const userData = useStore(state => state.userData);
  const companySelect = useStore(state => state.companySelected);
  const accountingFirmSelected = useStore(state => state.accountingFirmSelected);

  return (
    <SidebarApps
      userData={userData}
      urlParams={{
        companyId: companySelect?.companyId,
        accountingFirmId: companySelect?.accountingFirmId || accountingFirmSelected?.id,
      }}
    />
  );
}
